<script>
import DefaultMixin from '../mixin'
import {uploadArquivo,deleteArquivo, find, update} from "@/domain/vistoria/services"
import mockVistoria from "@/domain/vistoria/helpers/mockVistoria"
import {UFakeLoader} from "uloc-vue"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import GlobalBemMixin from "@/components/bem/globalBemMixin"
import AcaoVistoria from "@/components/vistoria/components/buttons/AcaoVistoria"
// import CampoInput from "@/components/vistoria/components/inputs/CampoInput"
import * as Status from "@/domain/vistoria/helpers/types"
import uploadArquivos from "@/components/bem/windows/uploadArquivos"
import MountForm from "components/forms/components/mount/Form.vue"
import globalFormMixin from "components/forms/globalMixin";

export default {
  name: 'Vistoria',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin, GlobalPersonMixin, GlobalBemMixin, globalFormMixin],
  components: {
    MountForm,
    // CampoInput,
    AcaoVistoria,
    ECol,
    ERow,
    UFakeLoader
  },
  data() {
    return {
      loading: true,
      vistoria: JSON.parse(JSON.stringify(mockVistoria)),
      editing: false,
      tipoArquivo: 4
    }
  },
  computed: {
    fotos() {
      return this.vistoria.arquivos.filter(arquivo => {
        return arquivo.arquivo.versions && arquivo.arquivo.versions.thumb && /(jpg|gif|png)$/.test(arquivo.arquivo.versions.thumb.url)
      })
    },
    Status() {
      return Status
    }
  },
  mounted() {
    this.$route.params.id && this.load(this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    load(id) {
      this.loading = true
      find(id)
          .then(response => {
            console.log(response)
            this.loading = false
            const data = response.data
            console.log(data)
            if (!data.integracaoApi) {
              data.integracaoApi = {}
            } else {
              // data.integracaoApi = JSON.parse(data.integracaoApi)
            }
            this.vistoria = response.data
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    async salvar() {
      const vistoria = JSON.parse(JSON.stringify(this.vistoria))
      this.$uloc.loading.show('Salvando dados preenchidos')
      this.saveForms()
      this.$uloc.loading.show('Salvando vistoria')
      update(vistoria, 'PATCH', 'atualizar')
          .then(response => {
            this.$uloc.loading.hide()
            this.dg()
            this.editing = false
            console.log(response.data)
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    excluirFoto(arquivo) {
      this.$uloc.dialog({
        title: 'Remover arquivo',
        message: `Tem certeza que deseja excluir este arquivo? O sistema irá registrar o log de exclusão.`,
        noCaps: true,
        ok: `Sim, remover`,
        cancel: 'Não'
      })
          .then(() => {
            this.$uloc.loading.show()
            deleteArquivo(this.vistoria.id, arquivo.id)
            .then (response => {
              this.$uloc.loading.hide()
              this.load(this.vistoria.id)
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
          })
          .catch(() => {
          })
    },
    uploadArquivos: uploadArquivos,
    uploadService (data) {
      console.log('Upload', data)
      return uploadArquivo(this.vistoria.id, data)
    },
    uploadServiceSuccess (response) {
      this.load(this.vistoria.id)
    }
  },
  meta: {
    title: 'Vistoria',
    name: 'Vistoria'
  }
}
</script>

<template>
  <div class="vistoria-container wrapper-md">
    <div class="sl-layout-page">
      <div v-if="vistoria.status === Status.STATUS_CANCELADO"
           class="u-alert-default u-alert-content wrapper-lg m-b no-select">
        Vistoria cancelada.
      </div>
      <div class="sl-layout-page-actions">
        <acao-vistoria :vistoria="vistoria" @atualizaBin="load($route.params.id)"/>
      </div>
      <div v-if="loading">
        <u-fake-loader width="90%"/>
        <br>
        <u-fake-loader width="30%"/>
        <u-fake-loader width="50%"/>
        <u-fake-loader width="40%"/>
        <br><br>
        <u-fake-loader width="20%"/>
        <u-fake-loader width="40%"/>
        <br>
        <br>
        <br>
        <br>
        <br>
        <u-fake-loader width="20%"/>
      </div>
      <div v-else-if="vistoria.id">
        <h2 class="diviser">Dados da Vistoria</h2>
        <div class="m-t">
          <e-row class="erp-row-view" mr>
            <e-col style="min-width: 50px">
              <label>ID</label>
              <p>{{ vistoria.id }}</p>
            </e-col>
            <e-col>
              <label>Registro</label>
              <p class="text-nowrap">{{ vistoria.createdAt|formatDate }}</p>
            </e-col>
            <e-col>
              <label>Início</label>
              <p class="text-nowrap">{{ vistoria.dataInicio|formatDate }}</p>
            </e-col>
            <e-col>
              <label>Conclusão</label>
              <p class="text-nowrap">{{ vistoria.dataVistoria|formatDate }}</p>
            </e-col>
            <e-col>
              <label>Vistoriador</label>
              <p v-if="vistoria.vistoriador" class="text-nowrap cursor-pointer"
                 @click="gotoPerson(vistoria.vistoriador)">{{ vistoria.vistoriador.name }} <i
                  class="fa fa-link font-10"></i></p>
              <p v-else>-</p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col style="min-width: 50px">
              <label class="text-nowrap">ID BEM</label>
              <p class="cursor-pointer text-nowrap" @click="gotoBem(vistoria.bem)">
                {{ vistoria.bem.id }} <span v-if="vistoria.bem.identificador">- {{ vistoria.bem.identificador }}</span>
              </p>
            </e-col>
            <e-col style="min-width: 100px">
              <label class="text-nowrap">LOTE</label>
              <p v-if="!editing">{{ vistoria.lote || '-' }}</p>
              <p v-else><input class="sl-input" v-model="vistoria.lote" /></p>
            </e-col>
            <e-col style="flex: 0; flex-wrap: nowrap; white-space: nowrap">
              <label>BEM</label>
              <p class="cursor-pointer" @click="gotoBem(vistoria.bem)">
                {{ vistoria.bem.descricao }}
                <i class="fa fa-link font-10"/></p>
            </e-col>
          </e-row>
          <e-row v-if="vistoria.entrada" class="erp-row-view" mr>
            <e-col style="min-width: 50px">
              <label class="text-nowrap">Entrada do Bem</label>
              <p></p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col style="flex: 1;">
              <label>Observações de Entrada</label>
              <p v-if="!editing">{{ vistoria.observacaoEntrada || '-' }}</p>
              <p v-else><textarea class="sl-textarea full-width" rows="6"
                                  v-model="vistoria.observacaoEntrada"></textarea></p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col style="flex: 1;">
              <label>Observações Internas</label>
              <p v-if="!editing">{{ vistoria.observacaoInterna || '-' }}</p>
              <p v-else><textarea class="sl-textarea full-width" rows="6"
                                  v-model="vistoria.observacaoInterna"></textarea></p>
            </e-col>
          </e-row>
<!--          <div v-if="editing">
            <e-row class="erp-row-view" mr>
              <e-col style="flex-wrap: nowrap; white-space: nowrap">
                <label>BIN - Número Motor</label>
                <p><input class="sl-input" v-model="vistoria.integracaoApi.NumeroMotor" /></p>
              </e-col>
            </e-row>
          </div>-->
          <e-row class="erp-row-view" mr>
            <e-col style="flex: 1;">
              <label>Resposta Webservices / Integrações</label>
              <p>{{ vistoria.integracaoApi || '-' }}</p>
            </e-col>
          </e-row>
        </div>
        <h2 class="diviser m-t-lg m-b-lg">Checklist</h2>
        <div class="app-forms-container box-contato-simple m-t-n e-input-modern fin-form simple-btn" v-for="form in vistoria.forms" :key="form.id">
          <mount-form :ref="'form' + form.id" :mode="editing ? 'edit' : 'print'" disable-save-btn hide-name :form="form" entity-name="vistoria" :response-entity-id="vistoria.id" />
        </div>
        <!--<e-row class="erp-row-view col-limit-3" mr>
          <e-col v-for="campo in vistoria.campos" :key="'campo-' + campo.id" style="flex: 1;">
            <label>{{ campo.campo.nome }}</label>
            <p>
              <campo-input class="full-width" v-model="campo.valor" :campo="campo.campo" :editing="editing"/>
            </p>
          </e-col>

        </e-row>
        <e-row class="erp-row-view col-limit-3" mr>
          <e-col v-for="acessorio in vistoria.acessorios" :key="'acessorio-' + acessorio.id" style="flex: 1;">
            <label>{{ acessorio.acessorio.nome }}</label>
            <p>
              <campo-input :campo="acessorio.acessorio" v-model="acessorio.valor" :editing="editing"/>
            </p>
          </e-col>
        </e-row>-->

        <h2 class="diviser m-t-lg m-b-lg">Fotos</h2>
        <e-row class="erp-row-view col-limit-5" mr>
          <e-col class="hoverable" v-for="foto in fotos" :key="'foto-' + foto.id" style="flex: 1;">
            <a :href="foto.arquivo.url" target="_blank"><img :src="foto.arquivo.versions.thumb.url"/></a>
            <div class="m-t-xs" v-if="foto.arquivo.referNome">{{ foto.arquivo.referNome }}</div>
            <div class="m-t-xs" v-if="foto.arquivo.refer">{{ foto.arquivo.refer }}</div>
            <a @click="excluirFoto(foto)" class="m-t hidden-nohover text-negative inline-block">Excluir</a>
          </e-col>
        </e-row>
        <u-btn @click="uploadArquivos" class="m-t-xs sl-btn" color="primary" no-caps label="Adicionar mais fotos"/>


        <div class="sl-layout-page-footer-btns">
          <u-btn @click="salvar" v-if="editing" class="m-t-lg sl-btn positive" no-caps label="Salvar"/>
        </div>

      </div>
    </div>
  </div>
</template>
